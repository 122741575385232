import { Program } from './program.model';

export interface ProgramState {
  programs: Program[];
  program: Program;
  loadingPrograms: boolean;
  loadingProgram: boolean;
  programUpdated: boolean;
  programDeleted: boolean;
}

export const emptyProgram: Program = {
  name: '',
  countries: '',
  companyMapId: null,
  originMapId: null,
  routeMapId: null,

  yulCourier: false,
  yulPuroPost: false,
  yulLTL: false,
  yyzCourier: false,
  yyzPuroPost: false,
  yyzLTL: false,
  yvrCourier: false,
  yvrPuroPost: false,
  yvrLTL: false,

  additionalRoutes: false,
  additionalRoutesName: '',

  yulCourierAdditionalRoute: false,
  yulPuroPostAdditionalRoute: false,
  yulLTLAdditionalRoute: false,
  yyzCourierAdditionalRoute: false,
  yyzPuroPostAdditionalRoute: false,
  yyzLTLAdditionalRoute: false,
  yvrCourierAdditionalRoute: false,
  yvrPuroPostAdditionalRoute: false,
  yvrLTLAdditionalRoute: false,

  defaultPuroPost: '',
  defaultCanadaPost: '',
  defaultCourier: '',
  defaultLTL: '',
  defaultOther: '',
  additionalPuroPost: '',
  additionalCanadaPost: '',
  additionalCourier: '',
  additionalLTL: '',
  additionalOther: '',

  narrative: ''
};

export const initialProgramState: ProgramState = {
  programs: [],
  program: emptyProgram,
  loadingPrograms: false,
  loadingProgram: false,
  programUpdated: false,
  programDeleted: false,
};
